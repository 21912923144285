html,
body {
  padding: 0;
  margin: 0;

  /* Required for setting the base font size of 1rem */
  font-size: 16px;
  background-color: #F4F8FF;
}

a {
  color: inherit;
  text-decoration: none;
}